<template>
  <div>
    <el-image
        v-for="(item,index) in imageListData"
        :key="index"
        style="width: 100px; height: 100px"
        :src="item.thumbnail"
        :preview-src-list="[item.url]"/>
  </div>
</template>

<script>
export default {
  name: 'imageShow',
  props: {
    imageList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    imageList(newVal, oldVal) {
      this.imageListData = newVal
      this.changeMessage()
    }
  },
  data() {
    return {
      imageListData: []
    }
  },
  created() {
    this.changeMessage()
  },
  methods: {
    changeMessage() {
      this.imageList.forEach(item => {
        item.thumbnail = item.url + '?download=0&width=100&height=100';
      })
      this.imageListData = this.imageList
    }
  }
}
</script>

<style scoped>
/deep/ img {
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
  margin-left: 5px;
}
</style>
